/* eslint-disable eol-last */
/* eslint-disable comma-dangle */
/* eslint-disable no-tabs */
/* eslint-disable indent */
export default [
	{
		title: 'Payment management',
		icon: 'CreditCardIcon',
		children: [
			{
				title: 'Payment type',
				route: 'payments-type',
				action: 'read',
				resource: 'paymenttypeindex',
			},
			{
				title: 'Deposit transactions',
				route: 'payments-deposit',
				action: 'view',
				resource: 'deposit',
			},
			/* {
				title: 'Auto withdrawal limit',
				route: 'payments-auto-withdrawal-limit',
				action: 'view',
				resource: 'autowithdrawallimit',
			}, */
			{
				title: 'Withdrawal transactions',
				route: 'payments-withdrawal',
				action: 'view',
				resource: 'withdrawal',
			},
			{
				title: 'Adjustment transactions',
				route: 'payments-adjustment',
				action: 'view',
				resource: 'adjustbalance',
			},		
			{
				title: 'Transfer',
				route: 'payments-transfer',
				action: 'view',
				resource: 'transfer',
			},
			{
				title: 'Card Transaction',
				route: 'payments-card-transaction',
				action: 'view',
				resource: 'cardtransaction',
			},
			{
				title: 'Deposit Type',
				route: 'payments-deposit-type',
				action: 'view',
				resource: 'deposittype',
			},
		],
	}
]