export default [
  {
    title: "Log Management",
    icon: "ArchiveIcon",
    children: [
      {
        title: "Mail Log",
        route: "logs-mail-log",
        action: "view",
        resource: "maillog",
      },
      {
        title: " Voice Otp Log",
        route: "logs-voice-otp-log",
        action: "view",
        resource: "voiceotplog",
      },
    ],
  },
];
