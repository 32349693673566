/* eslint-disable eol-last */
/* eslint-disable comma-dangle */
/* eslint-disable no-tabs */
/* eslint-disable indent */
export default [
    {
        title: 'Eligible Stake Amount',
        icon: 'TrendingUpIcon',
        children: [
            {
                title: 'Rebate Ratio',
                route: 'campaign-stake-amount-rebate-ratio'
            },
            {
                title: 'Report',
                route: 'campaign-stake-amount-report',
                action: 'view',
                resource: 'esa',
            }
        ]
    }
]