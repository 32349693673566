export default [
  {
    title: "Staff management",
    icon: "UserIcon",
    children: [
      {
        title: "User lists",
        route: "apps-members-list",
        action: "view",
        resource: "staff",
      },
      {
        title: "Roles",
        route: "apps-members-role",
        action: "view",
        resource: "role",
      },
      {
        title: "Department",
        route: "apps-members-department",
        action: "view",
        resource: "department",
      },
      {
        title: "Market",
        route: "",
        action: "view",
        resource: "market",
      },
      // {
      //   title: "Audit Login",
      //   route: "apps-members-audit-login",
      //   action: "read",
      //   resource: "userauditlogin",
      // },
    ],
  },
];
