<template>
  <b-nav-item-dropdown
    right
    toggle-class="d-flex align-items-center dropdown-user-link"
    class="dropdown-user"
  >
    <template #button-content>
      <div class="d-sm-flex d-none user-nav">
        <p class="user-name font-weight-bolder mb-0">
          {{ userData.fullName || userData.username }}
        </p>
        <!-- <span class="user-status">{{ userData.role }}</span> -->
      </div>
      <b-avatar
        size="40"
        :src="userData.avatar"
        variant="light-primary"
        badge
        class="badge-minimal"
        badge-variant="success"
      >
        <feather-icon v-if="!userData.fullName" icon="UserIcon" size="22" />
      </b-avatar>
    </template>

    <!-- <b-dropdown-item
			:to="{ name: 'pages-profile'}"
			link-class="d-flex align-items-center"
		>
			<feather-icon
				size="16"
				icon="UserIcon"
				class="mr-50"
			/>
			<span>{{ $t("Profile") }}</span>
		</b-dropdown-item>

		<b-dropdown-divider /> -->

		<!-- :to="{ name: 'auth-reset-password-v1' }" -->
		<b-dropdown-item
		@click="resetPassword"
      link-class="d-flex align-items-center"
    >
      <feather-icon size="16" icon="RefreshCcwIcon" class="mr-50" />
      <span>{{ $t("Reset Password") }}</span>
    </b-dropdown-item>
    <b-dropdown-item link-class="d-flex align-items-center" @click="logout">
      <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
      <span>{{ $t("Logout") }}</span>
    </b-dropdown-item></b-nav-item-dropdown
  >
</template>

<script>
import {
  BModal,
  VBModal,
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { initialAbility } from "@/libs/acl/config";
import useJwt from "@/auth/jwt/useJwt";
import { avatarText } from "@core/utils/filter";

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem("userData")),
      avatarText,
    };
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  methods: {
    resetPassword() {
		this.$router.push({ name: "auth-reset-password-v1" });
    },
    logout() {
      // Remove userData from localStorage
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName);
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName);

      // Remove userData from localStorage
      localStorage.removeItem("userData");
      localStorage.removeItem("token");
      localStorage.removeItem("userFunctions");

      // Reset ability
      this.$ability.update(initialAbility);

      // Redirect to login page
      this.$router.push({ name: "auth-login" });
    },
  },
  setup(){}
};
</script>
