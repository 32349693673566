export default [
  {
    title: "Accounting Management",
    icon: "DollarSignIcon",
    children: [
      {
        title: "Deposit/Withdrawal",
        route: "account-deposit-withdrawal",
        action: "read",
        resource: "accountingdepositwithdrawal",
      },
      {
        title: "Win/Loss",
        route: "account-win-loss",
        action: "read",
        resource: "accountingwinloss",
      },
    ],
  },
];
