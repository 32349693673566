export default [
	{
		title: 'Dashboards',
		icon: 'HomeIcon',
		route: 'dashboard-analytics',
		tagVariant: 'light-warning',
		action: 'read',
		resource: 'Auth',
	},
]
