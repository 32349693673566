/*

Array of object

Top level object can be:
1. Header
2. Group (Group can have navItems as children)
3. navItem

* Supported Options

/--- Header ---/

header

/--- nav Grp ---/

title
icon (if it's on top level)
tag
tagVariant
children

/--- nav Item ---/

icon (if it's on top level)
title
route: [route_obj/route_name] (I have to resolve name somehow from the route obj)
tag
tagVariant

*/
import dashboard from "./dashboard";
import payment from "./payment";
import user from "./users";
import member from "./members";
import affiliate from "./affiliate";
import bank from "./bank";
import bonus from "./bonus";
import marketing from "./marketing";
import stakeAmount from "./stake-amount";
import riskManagement from "./risk-management";
import localSetting from "./local-setting";
import log from "./log";
import reason from "./reason";
import redemption from "./redemption";
import oss from "./oss";
import agent from "./agent";
import agency from "./agency";
import compliance from "./compliance";
// import others from './others'
// import chartsAndMaps from './charts-and-maps'
// import uiElements from './ui-elements'
// import formAndTable from './forms-and-table'
import frontend from "./frontend";
import sports from "./sports";
// import log from "./log";
import account from "./account";

// Array of sections
// export default [...dashboard, ...appsAndPages, ...uiElements, ...formAndTable, ...chartsAndMaps, ...others]
export default [
  ...dashboard,
  ...account,
  ...member,
  ...bank,
  ...user,
  // ...affiliate,
  ...payment,
  // ...log,
  ...reason,
  // ...redemption,
  ...bonus,
  // ...oss,
  ...agent,
  ...agency,
  ...marketing,
  // ...compliance,
  ...stakeAmount,
  ...riskManagement,
  ...frontend,
  ...sports,
  ...log,
  ...localSetting,
];
