export default [
	{
		title: 'Category Management',
		icon: 'EditIcon',
		children: [
			{
				title: 'Adjustment reason',
				route: 'reason-adjustment',
				action: 'read',
				resource: 'Auth',
				// Chưa có role trên server
			},
			{
				title: ' Template Sms Management',
				route: 'template-sms',
				action: 'view',
				resource: 'smstemplate',
			},
			{
				title: ' Title Sms Management',
				route: 'title-sms',
				action: 'view',
				resource: 'smstitle',
			},
		]
	},
]