export default [
  {
    title: "Member management",
    icon: "UsersIcon",
    children: [
      {
        title: "Member lists",
        route: "apps-users-list",
        action: "view",
        resource: "user",
      },
      {
        title: "Tag",
        route: "apps-users-tag",
        action: "read",
        resource: "tagtypeindex",
      },

      {
        title: "Login Log",
        route: "apps-users-audit-login",
        action: "read",
        resource: "userauditlogin",
      },
      {
        title: "Audit Ip",
        route: "apps-users-audit-ip",
        action: "read",
        resource: "userauditip",
      },
      {
        title: "Audit Name",
        route: "apps-users-audit-name",
        action: "read",
        resource: "userauditname",
      },
      {
        title: "Log Send Message",
        route: "apps-users-log-send-message",
        action: "view",
        resource: "usersendmessage",
      },
      {
        title: "Audit Bank Account",
        route: "apps-users-audit-bank-account",
        action: "read",
        resource: "userauditbankaccounts",
      },
      {
        title: "Kyc",
        route: "apps-users-kyc",
        action: "read",
        resource: "userkycmanagement",
      },
    ],
  },
];
