export default [
	{
		title: 'Vendor Management',
		icon: 'AtSignIcon',
		children: [
			/* {
				title: 'Agent',
				route: 'agent-list',
				action: 'view',
				resource: 'agent',
			}, */
			{
				title: 'Game wallet',
				route: 'agent-wallet',
				action: 'read',
				resource: 'gamewalletmanagement',
			},
			/* {
				title: 'Product',
				route: 'agent-product',
				action: 'read',
				resource: 'Auth',
			},
			{
				title: 'Game',
				route: 'agent-game',
				action: 'read',
				resource: 'Auth',
			}, */
			{
				title: 'Game type',
				route: 'agent-game-type',
				action: 'read',
				resource: 'gamemanagement',
			},
			{
				title: 'Game category',
				route: 'agent-game-category',
				action: 'read',
				resource: 'gamecategorymanagement',
			},
			{
				title: 'Win Loss By User',
				route: 'agent-win-loss-by-user',
				action: 'read',
				resource: 'agentwinloss',
			},
			{
				title: 'Win Loss By Product',
				route: 'agent-win-loss-by-product',
				action: 'read',
				resource: 'agentwinlossbyproduct',
			},
			{
				title: 'Win Loss General',
				route: 'agent-win-loss-general',
				action: 'read',
				resource: 'agentwinlossgeneral',
			},
		]
	},
]