export default [
  {
    title: "Bank management",
    icon: "CreditCardIcon",
    children: [
      {
        title: "User bank account",
        route: "payments-bank-account",
        action: "view",
        resource: "userbankaccount",
      },
      {
        title: "Local bank account",
        route: "payments-local-bank-account",
        action: "view",
        resource: "localbankaccount",
      },
      {
        title: "Bank Management",
        route: "bank-manager",
        action: "view",
        resource: "bank",
      },
    ],
  },
];
