export default [
    {
        title: 'Agency Management',
        icon: 'SunriseIcon',
        children: [
            {
                title: 'Agency List',
                route: 'agency',
                action: 'read',
                resource: 'agencylistmanagement',
            },
            {
                title: 'Agency Commissions',
                route: 'agency-commission',
                action: 'read',
                resource: 'agencycommissionsmanagement',
            },
            {
                title: 'Revenue Setting',
                route: 'agency-revenue-setting',
                action: 'read',
                resource: 'agencyrevenuesetting',
            },
            {
                title: 'Agency Login Log',
                route: 'agency-login-log',
                action: 'view',
                resource: 'agencyloginlog',
            },
        ]
    }
]