/* eslint-disable eol-last */
/* eslint-disable comma-dangle */
/* eslint-disable no-tabs */
/* eslint-disable indent */
export default [
	{
		title: "Setting",
		icon: "SettingsIcon",
		children: [
			{
				title: "Param Management",
				route: "local-setting-setting-param",
				action: "view",
				resource: "param",
			},
			{
				title: "Balance Type Management",
				route: "local-setting-setting-balance-type",
				action: "view",
				resource: "balancetype",
			},
			{
				title: "Third Partner Management",
				route: "local-setting-setting-third-partner",
				action: "view",
				resource: "thirdpartner",
			},
			{
				title: "Level Rank Management",
				route: "local-setting-setting-level-rank",
				action: "view",
				resource: "levelrank",
			},
		],
	},
	// {
	// 	title: 'Settings',
	// 	icon: 'SettingsIcon',
	// 	route: 'local-setting-setting'
	// },
	{
		title: 'Notifications',
		icon: 'BellIcon',
		route: 'local-setting-notification'
	}
]