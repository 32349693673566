/* eslint-disable eol-last */
/* eslint-disable comma-dangle */
/* eslint-disable no-tabs */
/* eslint-disable indent */
export default [
    {
        title: 'Frontend Management',
        icon: 'GlobeIcon',
        children: [
            {
                title: 'Banners',
                route: 'frontend-banners-list',
                action: 'view',
				resource: 'banner',
            },
            {
                title: 'Notifications',
                route: 'frontend-notifications-list',
                action: 'view',
				resource: 'notification',
            },
            {
                title: 'Post category',
                route: 'frontend-post-category',
                action: 'view',
				resource: 'postcategory',
            },
            {
                title: 'Post',
                route: 'frontend-post',
                action: 'view',
				resource: 'post',
            },
            {
                title: 'Information',
                route: 'frontend-information',
                action: 'view',
				resource: 'info',
            },
            {
                title: 'Contact Category',
                route: 'frontend-contact-category',
                action: 'view',
				resource: 'contactcategory',
            },
            {
                title: 'Contact',
                route: 'frontend-contact',
                action: 'read',
				resource: 'contactmanager',
                // role bị trùng key contact_manager
            },
        ]
    }
]