export default [
  {
    title: "Sports Management",
    icon: "FlagIcon",
    // action: "read",
    // resource: "sportmanagement",
    children: [
      {
        title: "Sports League List",
        route: "sports-league-list",
        action: "read",
        resource: "sportleaguepermission",
      },
      {
        title: "Sports Type",
        route: "sports-type",
        action: "read",
        resource: "sporttypepermission",
      },
      {
        title: "Sports Season",
        route: "sports-season",
        action: "read",
        resource: "sportseasonpermission",
      },
      {
        title: "Sports Team",
        route: "sports-team",
        action: "read",
        resource: "sportteampermission",
      },
      {
        title: "Hot Sport Match",
        route: "hot-sport-match",
        action: "view",
        resource: "sportmatch",
      },
      {
        title: "BV Hot Sport Match",
        route: "bv-hot-sport-match",
        action: "view",
        resource: "bvsporthotmatch",
      },
    //   {
    //     title: "Deposit transactions",
    //     route: "payments-deposit",
    //     action: "view",
    //     resource: "deposit",
    //   },
    ],
  },
];
