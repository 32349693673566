/* eslint-disable eol-last */
/* eslint-disable comma-dangle */
/* eslint-disable no-tabs */
/* eslint-disable indent */
export default [
    {
        title: 'Risk Management',
        icon: 'GiftIcon',
        children: [
            {
                title: 'Top Winners',
                route: 'campaign-risk-management-top-winners',
                action: 'read',
				resource: 'topwinners',
            },
            {
                title: 'Top Balance',
                route: 'campaign-risk-management-top-balance'
            },
            {
                title: 'Top Deposit',
                route: 'campaign-risk-management-top-deposit',
                action: 'view',
				resource: 'topdeposit',
            },
            {
                // title: 'Top Transfer',
                // route: 'campaign-risk-management-top-transfer',
                // action: 'view',
				// resource: 'toptransfer',
            },
            {
                title: 'Top Withdrawal',
                route: 'campaign-risk-management-top-withdrawal',
                action: 'view',
				resource: 'topwithdrawal',
            },
        ]
    }
]